<template>
    <div class="flex outerwrap">
        
        <div class="w-50 body-wrap">
            <h2>Is your quote for Windows or Doors?</h2>

            <div class="flex justify-between">
                <div class="square-wrap" @click="choiceActive('doors', $event); activateButton()">
                    <div class="square" :class="{squareActive : this.$data.doors == true}" >
                        <div class="upper-right-square" :class="{flex : this.$data.doors  == true}"><i class="fal fa-check"></i></div>
                        <img class="iconImage" src="../assets/img/curved.png">
                    </div>
                    <p>Doors</p>
                </div>
                
                <div class="square-wrap" @click="choiceActive('windows', $event), activateButton()">
                    <div class="square" :class="{squareActive : this.$data.windows == true}">
                        <div class="upper-right-square" :class="{flex : this.$data.windows  == true}"><i class="fal fa-check"></i></div>
                        <img class="iconImage" src="../assets/img/round.png">
                    </div>
                    <p>Windows</p>
                </div>
            </div>
            <div class="flex">
                <button ref="backButton" @click="emitBack" class="backButton">BACK</button>
                <button ref="nextButton" @click="progressAndChoice" class="nextButton" disabled>CONTINUE</button>
            </div>
            
        </div>
        <img class="w-50 mainImg" src="../assets/img/mainImg.jpeg">

    </div>
</template>
<script>


export default {
    methods: {
        progressAndChoice() {
            //
            var typeChoice

            if ( this.doors == true ) {
                typeChoice = 'Door'
            } else if ( this.windows == true) {
                typeChoice = 'Windows'
            }
            
            this.$emit('progressAndChoice', typeChoice)
        },
        emitBack() {
            this.$emit('progressBack')
        },
        activateButton() {
            if ( this.windows == false && this.doors == false ) {
                this.$refs.nextButton.classList.remove('nextButtonActive')
                this.$refs.nextButton.setAttribute("disabled")
            } else {
                console.log(this.$refs.nextButton)
                this.$refs.nextButton.classList.add('nextButtonActive')
                this.$refs.nextButton.removeAttribute("disabled")
            }
        },
        choiceActive(choice, e) {
                // Takes the option to enable, the option to disable (if its already enabled) and the event
                if (choice == 'doors') {
                    this.doors = true
                    this.windows = false
                }

                if (choice == 'windows') {
                    this.doors = false
                    this.windows = true
                }
            },
    },
    data() {
        return {
            windows: false,
            doors: false
        }
    }
}
</script>


<style scoped>
    .nextButton {
        margin-top: 2rem;
        background-color: #87ab95;
        cursor: not-allowed;
    }

    .backButton {
        margin-top: 2rem;
    }

    .nextButtonActive {
        background-color: #13843F;
        cursor: pointer;
    } 
</style>