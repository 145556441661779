<template>
    <div class="flex outerwrap">
        
        <div class="w-50 body-wrap">
            <h2>What style door do you need?</h2>

            <div class="flex justify-between">
                <div class="square-wrap" @click="chooseDoor('single', 'double')">
                    <div class="square" :class="{squareActive : this.$data.single == true}" >
                        <div class="upper-right-square" :class="{flex : this.$data.single  == true}"><i class="fal fa-check"></i></div>
                        <img class="iconImage" src="../assets/img/curved.png">
                    </div>
                    <p>Arched Single Door</p>
                </div>
                
                <div class="square-wrap" @click="chooseDoor('double', 'single')">
                    <div class="square" :class="{squareActive : this.$data.double == true}">
                        <div class="upper-right-square" :class="{flex : this.$data.double  == true}"><i class="fal fa-check"></i></div>
                        <img class="iconImage" src="../assets/img/curved.png">
                    </div>
                    <p>Arched Double Door</p>
                </div>
            </div>
            <div class="flex mt-4 flex-wrap justify-between">
                <div class="dimension-input hidden block">
                    <label for="width">Width (mm)</label>
                    <input @change="checkOversized()" ref="width" type="number" max="1800" name="width" id="width">
                </div>

                <div class="dimension-input hidden block">
                    <label for="height">Height (mm)</label>
                    <input @change="checkOversized()" ref="height" type="number" max="2100" name="height" id="height">
                </div>

                <p class="mb-0 mt-4" v-if="this.oversized">Please contact the office for quotes on doors of this size.</p>
                <p class="mb-0 mt-4" v-if="this.doorTooSmall">Minimum door sizes are 600mm for single doors & 1200mm for double doors.</p>
            </div>

            <div class="flex">
                <button ref="backButton" @click="emitBack" class="backButton">BACK</button>
                <button ref="nextButton" @click="progressAndDoor" class="nextButton" disabled>CONTINUE</button>
            </div>
        </div>
        <img class="w-50 mainImg" src="../assets/img/mainImg.jpeg">

    </div>

</template>

<script>
export default {
    data() {
        return {
            double: false,
            single: false,
            oversized: false,
            doorTooSmall: false,
        }
    },
    methods: {
        chooseDoor(doorChoice, disable) {
            this[doorChoice] = !this[doorChoice]
            this[disable] = false

            // if (this.single == true || this.double == true) {
            //     this.$refs.nextButton.classList.add('nextButtonActive')
            //     this.$refs.nextButton.removeAttribute("disabled")
            // } else {
            //     this.$refs.nextButton.classList.remove('nextButtonActive')
            //     this.$refs.nextButton.setAttribute("disabled", true)
            // }
        },
        emitBack() {
            this.$emit('progressBack')
        },
        progressAndDoor() {
            var doorStyle
            if ( this.single == true ) {
                doorStyle = 'single'
            } else if (this.double == true) {
                doorStyle = 'double'
            }

            var dimensions = {
                width: this.$refs.width.value,
                height: this.$refs.height.value
            }

            this.$emit('progressAndDoor', doorStyle, dimensions)
        },
        checkOversized() {

            var height = this.$refs.height.value
            var width  = this.$refs.width.value

            if ( this.single == true ) {
                if ( height > 2500 || width > 1000 ) {
                    this.oversized = true
                    this.doorTooSmall = false
                    this.$refs.nextButton.classList.remove('nextButtonActive')
                    this.$refs.nextButton.setAttribute("disabled", true)
                } else if (width < 600) {
                    this.doorTooSmall = true
                    this.oversized =
                    this.$refs.nextButton.classList.remove('nextButtonActive')
                    this.$refs.nextButton.setAttribute("disabled", true)
                } else {
                    this.oversized = false
                    this.doorTooSmall = false
                    this.$refs.nextButton.classList.add('nextButtonActive')
                    this.$refs.nextButton.removeAttribute("disabled")
                }
            }

            if ( this.double == true ) {
                if ( height > 2500 || width > 2000 ) {
                    this.oversized = true
                    this.$refs.nextButton.classList.remove('nextButtonActive')
                    this.$refs.nextButton.setAttribute("disabled", true)
                    this.doorTooSmall = false
                } else if (width < 1200 && width > 0) {
                    this.doorTooSmall = true
                    this.$refs.nextButton.classList.remove('nextButtonActive')
                    this.$refs.nextButton.setAttribute("disabled", true)
                } else {
                    this.doorTooSmall = false
                    this.oversized = false
                    this.$refs.nextButton.classList.add('nextButtonActive')
                    this.$refs.nextButton.removeAttribute("disabled")
                }
            } 
        }
    }
}
</script>

<style scoped>
    .nextButton {
        margin-top: 2rem;
        background-color: #87ab95;
        cursor: not-allowed;
    }

    .backButton {
        margin-top: 2rem;
    }

    .nextButtonActive {
        background-color: #13843F;
        cursor: pointer;
    }

    .fa-door-closed {
        font-size: 130px;
        color: #dadada;
    }

    .dimension-input {
        width: 45%;
        margin: 0.5rem 0;
    }

    label {
        width: 100%;
        margin-bottom: 0.5rem;
        display: block;
        font-weight: bold;
        color: #525252;
    }

    input {
        width: 100%;
        padding: 1rem;
        padding-right: 0;
        border: 1px solid #C6C6C6;
    }
</style>