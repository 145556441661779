<template>
    <div class="flex outerwrap">
        
        <div class="w-50 body-wrap">
            <h2>Type of window</h2>
            <p>Tell us about the type of windows you're looking for</p>

            <div class="flex justify-between">
                <div class="square-wrap" @click="choiceActive('arched', 'circular', $event)">
                    <div class="square" :class="{squareActive : this.$data.type.arched == true}" >
                        <div class="upper-right-square" :class="{flex : this.$data.type.arched  == true}"><i class="fal fa-check"></i></div>
                        <img class="iconImage" src="../assets/img/curved.png">
                    </div>
                    <p>Arched Window</p>
                </div>
                
                <div class="square-wrap" @click="choiceActive('circular', 'arched', $event)">
                    <div class="square" :class="{squareActive : this.$data.type.circular == true}">
                        <div class="upper-right-square" :class="{flex : this.$data.type.circular  == true}"><i class="fal fa-check"></i></div>
                        <img class="iconImage" src="../assets/img/round.png">
                    </div>
                    <p>Circular Window</p>
                </div>
            </div>

            <p v-if=" this.type.arched == true ">Please note: if the arc is an elliptical bend, a template will be required.</p>

            <div v-if="this.type.arched == true || this.type.circular == true">
                <h2 class="openingtitle">Required Openings</h2>
                <div class="flex justify-between">
                    <div class="square-wrap" @click="openingsActive('noOpening', 'openingRequired', $event)">
                        <div class="square" :class="{squareActive : this.$data.opening.noOpening == true}" >
                            <div class="upper-right-square" :class="{flex : this.$data.opening.noOpening  == true}"><i class="fal fa-check"></i></div>
                            <img v-if="this.type.circular == true" class="iconImage" src="../assets/img/round.png">
                            <img v-if="this.type.arched == true" class="iconImage" src="../assets/img/curved.png">
                        </div>
                        <p>No openings</p>
                    </div>
                    
                    <div class="square-wrap" @click="openingsActive('openingRequired', 'noOpening', $event)">
                        <div class="square" :class="{squareActive : this.$data.opening.openingRequired == true}">
                            <div class="upper-right-square" :class="{flex : this.$data.opening.openingRequired  == true}"><i class="fal fa-check"></i></div>
                            <img v-if="this.type.circular == true" class="iconImage" src="../assets/img/round.png">
                            <img v-if="this.type.arched == true" class="iconImage" src="../assets/img/curved.png">
                        </div>
                        <p>Opening required</p>
                    </div>
                </div>
            </div>

            <div v-if="this.opening.openingRequired == true && this.type.arched == true" >
                <h2 class="openingtitle">Opening Type</h2>
                <div class="flex justify-between">
                    <div class="square-wrap" @click="hungActive('sideHung', 'topHung', $event)">
                        <div class="square" :class="{squareActive : this.$data.openingType.sideHung == true}" >
                            <div class="upper-right-square" :class="{flex : this.$data.openingType.sideHung  == true}"><i class="fal fa-check"></i></div>
                            <img class="iconImage" src="../assets/img/sidehung.png">
                        </div>
                        <p>Side Hung</p>
                    </div>
                    
                    <div class="square-wrap" @click="hungActive('topHung', 'sideHung', $event)">
                        <div class="square" :class="{squareActive : this.$data.openingType.topHung == true}">
                            <div class="upper-right-square" :class="{flex : this.$data.openingType.topHung  == true}"><i class="fal fa-check"></i></div>
                            <img class="iconImage" src="../assets/img/tophung.png">
                        </div>
                        <div>
                            <p>Top Hung</p>
                            <p class="hungventnotice">Please note: To achieve a top hung opening vent a straight is required, we cannot place opening vents on a curve.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="hidden" :class="{block : this.$data.type.arched || this.$data.type.circular }">
                <h2 class="openingtitle">Dimensions</h2>
                <p>All dimensions in mm</p>
            </div>
            <div class="flex flex-wrap justify-between">
                <div :class="{block : this.$data.type.arched}" class="dimension-input hidden">
                    <label for="width">Width (mm)</label>
                    <input ref="width" @change="setWidth(); checkOversizedHeight();" type="number" max="2500" name="width" id="width">
                </div>

                <div :class="{block : this.$data.type.arched}" class="dimension-input hidden">
                    <label for="height">Height (mm)</label>
                    <input ref="height" @change="setHeight(); checkOversizedHeight();" type="number" max="2500" name="height" id="height">
                </div>

                <p :class="{block : this.$data.type.arched}" class="mb-0 mt-4 hidden" v-if="this.oversizedHeight">Our arched windows have a max width and height of 4m. The area of the window must not be larger than 6.25m&sup2;. Please contact the office to discuss windows larger than these sizes.</p>

                <p :class="{block : this.$data.type.arched}" class="mb-0 mt-4 hidden" v-if="this.tooSmall">Our arched windows have a minimum width and height of 500mm.</p>

                <div :class="{block : this.$data.type.circular}" class="dimension-input hidden w-100">
                    <label for="diameter">Diameter (mm)</label>
                    <input ref="diameter" @change="setDiameter(); checkOversizedDiam();" type="number" name="diameter" id="diameter">
                </div>

                <p :class="{block : this.$data.type.circular}" class="mb-0 mt-4 hidden" v-if="this.oversizedDiameter">Please contact the office for quotes on extra large windows of this size.</p>

                <p :class="{block : this.$data.type.circular}" class="mb-0 mt-4 hidden" v-if="this.tooSmall == true && this.opening.noOpening == true">Circular Windows must have a minimum diameter of at least 300mm.</p>

                <p :class="{block : this.$data.type.circular}" class="mb-0 mt-4 hidden" v-if="this.tooSmall == true && this.opening.openingRequired == true">Circular Windows must have a minimum diameter of at least 600mm.</p>
            </div>
            
            <div class="flex">
                <button ref="backButton" @click="emitBack" class="backButton">BACK</button>
                <button ref="nextButton" @click="emitProgress" class="nextButton" disabled>NEXT STEP</button>
            </div>
        </div>
        <img class="w-50 mainImg" src="../assets/img/mainImg.jpeg">

    </div>

    
</template>

<script>
    export default {

        data() {
            return {
                // Window Type Choice
                type: {
                    arched: false,
                    circular: false
                },
                opening: {
                    noOpening: false,
                    openingRequired: false
                },
                openingType: {
                    topHung: false,
                    sideHung: false,
                },
                dimensions: {
                    width: '',
                    height: '',
                    diameter: ''
                },
                oversizedHeight: false,
                oversizedDiameter: false,
                tooSmall: false
            }
        },

        methods: {
            emitProgress() {
                this.$emit('progressclicked')
                this.$emit('emitData', this.$data)
                this.$emit('calcSize', this.$data)
            },
            emitBack() {
                this.$emit("progressBack")
            },
            checkOversizedHeight() {
                
                if( this.$refs.height.value >= 4000 || this.$refs.width.value >= 4000 ) {
                    this.oversizedHeight = true
                    this.$refs.nextButton.classList.remove('nextButtonActive')
                    this.$refs.nextButton.setAttribute("disabled", true)

                } else if ( this.$refs.height.value * this.$refs.width.value > 6250000 ) {
                    this.oversizedHeight = true
                    this.$refs.nextButton.classList.remove('nextButtonActive')
                    this.$refs.nextButton.setAttribute("disabled", true)
                } else if ( this.$refs.height.value <= 499 || this.$refs.width.value <= 499 ) {
                    this.tooSmall = true
                    this.oversizedHeight = false
                    this.$refs.nextButton.classList.remove('nextButtonActive')
                    this.$refs.nextButton.setAttribute("disabled", true)
                }   else {
                        this.oversizedHeight = false
                        this.tooSmall = false
                        this.$refs.nextButton.classList.add('nextButtonActive')
                        this.$refs.nextButton.removeAttribute("disabled")
                }
                    
            },
            checkOversizedDiam() {
                if ( this.opening.noOpening ) {
                    if ( this.$refs.diameter.value >= 1800) {
                        this.oversizedDiameter = true
                        this.tooSmall = false
                        this.$refs.nextButton.classList.remove('nextButtonActive')
                        this.$refs.nextButton.setAttribute("disabled", true)
                    } else if (this.$refs.diameter.value <= 299 && this.$refs.diameter.value > 0) {
                        this.tooSmall = true
                        this.$refs.nextButton.classList.remove('nextButtonActive')
                        this.$refs.nextButton.setAttribute("disabled", true)
                        this.oversizedDiameter = false
                    } else {
                        this.$refs.nextButton.classList.add('nextButtonActive')
                        this.$refs.nextButton.removeAttribute("disabled")
                        this.oversizedDiameter = false
                        this.tooSmall = false
                    }
                } else if ( this.opening.openingRequired ) {
                    if ( this.$refs.diameter.value >= 1400) {
                        this.oversizedDiameter = true
                        this.tooSmall = false
                        this.$refs.nextButton.classList.remove('nextButtonActive')
                        this.$refs.nextButton.setAttribute("disabled", true)
                    } else if (this.$refs.diameter.value < 600 && this.$refs.diameter.value > 0) {
                        this.tooSmall = true
                        this.$refs.nextButton.classList.remove('nextButtonActive')
                        this.$refs.nextButton.setAttribute("disabled", true)
                        this.oversizedDiameter = false
                    } else {
                        this.$refs.nextButton.classList.add('nextButtonActive')
                        this.$refs.nextButton.removeAttribute("disabled")
                        this.oversizedDiameter = false
                        this.tooSmall = false
                    }
                }
            },
            choiceActive(type, disable, e) {
                // Takes the option to enable, the option to disable (if its already enabled) and the event
                this.type[type] = !this.type[type]
                this.type[disable] = false
                
                this.$refs.height.value = null
                this.$refs.width.value = null
                this.$refs.diameter.value = null
                this.checkOversizedHeight()
                this.checkOversizedDiam()

                if (this.type.arched == false && this.type.circular == false) {
                    this.$refs.nextButton.classList.remove('nextButtonActive')
                    this.$refs.nextButton.setAttribute("disabled", true)
                    
                } else {
                    if ( this.opening.noOpening == true || this.opening.openingRequired == true ) {
                    this.$refs.nextButton.classList.add('nextButtonActive')
                    this.$refs.nextButton.removeAttribute("disabled")

                    } else {
                        this.$refs.nextButton.classList.remove('nextButtonActive')
                        this.$refs.nextButton.setAttribute("disabled", true)
                    }
                }
            },
            openingsActive(type, disable, e) {
                this.opening[type] = !this.opening[type]
                this.opening[disable] = false
                if(this.dimensions.diameter !== '' || this.dimensions.height !== '') {
                    if (this.opening.noOpening == false && this.opening.openingRequired == false) {
                        this.$refs.nextButton.classList.remove('nextButtonActive')
                        this.$refs.nextButton.setAttribute("disabled", true)
                    } else {
                        if ( this.type.arched == true || this.type.circular == true ) {
                        this.$refs.nextButton.classList.add('nextButtonActive')
                        this.$refs.nextButton.removeAttribute("disabled")
                        } else {
                            this.$refs.nextButton.classList.remove('nextButtonActive')
                            this.$refs.nextButton.setAttribute("disabled", true)
                        }
                    }
                }

                
            },
            hungActive(type, disable, e) {
                this.openingType[type] = !this.openingType[type]
                this.openingType[disable] = false

                if(this.dimensions.diameter !== '' || this.dimensions.height !== '') {
                    if(this.opening.openingRequired) {
                        if (this.openingType.topHung == false && this.openingType.sideHung == false) {
                            this.$refs.nextButton.classList.remove('nextButtonActive')
                            this.$refs.nextButton.setAttribute("disabled", true)
                        } else {
                            if ( this.type.arched == true || this.type.circular == true ) {
                            this.$refs.nextButton.classList.add('nextButtonActive')
                            this.$refs.nextButton.removeAttribute("disabled")
                            } else {
                                this.$refs.nextButton.classList.remove('nextButtonActive')
                                this.$refs.nextButton.setAttribute("disabled", true)
                            }
                        }
                    }
                }
                

                
                
            },
            setWidth() {
                this.dimensions.width = this.$refs.width.value
            },
            setHeight() {
                this.dimensions.height = this.$refs.height.value
            },
            setDiameter() {
                this.dimensions.diameter = this.$refs.diameter.value
            }
        }
    }

</script>

<style scoped>
    h2 {
        margin-bottom: 0;
    }

    p {
        margin-top: 0.5rem;
        color: #525252;
    }

    .hungventnotice {
        font-size: 0.8rem;
        width: 200px;
    }

    .square {
        background-color: #EDEDED;
        height: 200px;
        width: 200px;
        position: relative;
        margin: 1rem;
        margin-left: 0;
    }

    .square-wrap {
        cursor: pointer;
        text-align: center;
    }

    .square-wrap p {
        font-weight: bold;
    }

    .squareActive {
        background-color: #39A864;
    }

    .upper-right-square {
        height: 30px;
        width: 30px;
        position: absolute;
        top: 0;
        right: 0;
        background-color: red;
        display: flex;
        justify-content: center;
        align-items: center;
        display: none;
        background-color: #13843F;
        color: white;
    }

    .iconImage {
        width: 200px;
        height: 200px;
    }

    .openingtitle {
        margin-top: 2rem;
    }

    .dimension-input {
        width: 45%;
        margin: 0.5rem 0;
    }

    label {
        width: 100%;
        margin-bottom: 0.5rem;
        display: block;
        font-weight: bold;
        color: #525252;
    }

    input {
        width: 100%;
        padding: 1rem;
        padding-right: 0;
        border: 1px solid #C6C6C6;
    }
    .nextButton {
        margin-top: 2rem;
        background-color: #87ab95;
        cursor: not-allowed;
    }

    .backButton {
        margin-top: 2rem;
    }

    .nextButtonActive {
        background-color: #13843F;
        cursor: pointer;
    }

    @media only screen and (max-width: 1000px) {
        
    }

    @media only screen and (max-width: 600px) {

        .outerwrap {
            flex-direction: column;
        }

        .mainImg {
            padding: 0 !important;
        }

        .body-wrap {
            text-align: center;
        }

        .square, .iconImage {
            height: 125px;
            width: 125px;
        }
    }

    @media only screen and (max-width: 400px) {

        .square, .iconImage {
            height: 75px;
            width: 75px;
        }

        .square {
            margin-right: 0;
        }

        .square-wrap p {
            text-align: center;
        }

        .square-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
</style>