<template>
    <div class="flex outerwrap">
        
        <div class="w-50 body-wrap">
            <h2>Your Quote</h2>
            <div class="flex">
                <p class="tablecell text-left font-bold">{{choice}} Size</p>
                <p class="tablecell">{{windowSize}}</p>
            </div>
            <div v-if="openingRequired && openingType == 'arched'" class="flex">
                <p class="tablecell text-left font-bold">Opening Type</p>
                <p class="tablecell">{{openingType}}</p>
            </div>

            <p v-if="choice=='Windows'">Between {{priceLower}} - {{priceHigher}} per window depending on the complexity of the window profiles and delivery costs. This quote is a budget rate only, and a firm offer will be made when final details and templates can be inspected for bending suitability. </p>
            <p v-if="openingRequired">Please contact the office to discuss the pricing for windows with openings.</p>
            <p v-if="choice=='Windows'" class="pleasenote">Please note this is using a T/B Aluminium window outerframe</p>

            <p v-if="choice=='Door'">Between {{priceLower}} - {{priceHigher}} depending on the complexity of the door and delivery costs. This quote is a budget rate only, and a firm offer will be made when final details and templates can be inspected for bending suitability. </p>
            <p v-if="choice=='Door'" class="pleasenote">Please note this is using a T/B Aluminium door outerframe</p>
            <form class="flex">
                <button type="button" @click="emitBack" class="backButton">BACK</button>
                <button type="button" @click="emitProgress($event)" class="nextButton">REQUEST A CALLBACK</button>
            </form>
        </div>
        <img class="w-50 mainImg" src="../assets/img/mainImg.jpeg">

    </div>

    
</template>

<script>
    export default {
        methods: {
            emitProgress($event) {
                this.$emit('progressclicked', $event)
            },
            emitBack() {
                this.$emit('progressBack')
            }
        },
        props: ['windowSize', 'priceHigher', 'priceLower', 'choice', 'openingRequired', 'openingType']
    }
</script>

<style scoped>
    .body-wrap {
        padding: 1rem 6rem;
        text-align: left;
    }

    .tablecell {
        width: 50%;
        border: 1px solid #C9C9C9;
        padding: 2rem 2rem;
        text-align: center;
        color: #525252;
        margin: 0;
    }

    .pleasenote {
        font-size: 0.75rem;
    }

    .nextButton {
        margin-top: 3rem !important;
    }

    .backButton {
        margin-top: 3rem;
    }
</style>