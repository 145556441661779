<template>
    <div class="flex outerwrap">
        
        <div class="w-50 form-wrap">
            <h2>About You</h2>
            <form>
                <div class="input-wrap">
                    <label for="name">Your Name</label>
                    <input :value=formData.name ref="name" @change="emitName" type="text" id="name" name="name">
                </div>

                

                <div class="input-wrap">
                    <label for="postcode">Your Postcode</label>
                    <input :value=formData.postcode ref="postcode" @change="emitPostcode" type="text" id="postcode" name="postcode">
                </div>

                <div class="input-wrap">
                    <label for="email">Your Email</label>
                    <input :value=formData.email ref="email" @change="emitEmail" type="email" id="email" name="email">
                </div>

                <div class="input-wrap">
                    <label for="phone">Your Telephone</label>
                    <input :value=formData.telephone ref="telephone" @change="emitTelephone" type="tel" id="phone" name="telephone">
                </div>

            </form>

            
            <button @click="emitProgress" class="nextButton">NEXT STEP</button>
    
        </div>
        <img class="w-50 mainImg" src="../assets/img/mainImg.jpeg">

    </div>

    
</template>

<script>
    export default {
        methods: {
            emitProgress() {
                this.$emit('progressclicked')
            },
            emitName() {
                this.$emit('formChanged', (this.$refs.name))
            },

            emitPostcode() {
                this.$emit('formChanged', (this.$refs.postcode))
            },

            emitEmail() {
                this.$emit('formChanged', (this.$refs.email))
            },

            emitTelephone() {
                this.$emit('formChanged', (this.$refs.telephone))
            }
        },
        props: ['formData'],
    }
</script>

<style scoped>

    label {
        width: 100%;
        margin-bottom: 0.5rem;
        display: block;
        font-weight: bold;
        color: #525252;
    }

    input {
        width: 100%;
        padding: 1rem;
        border: 1px solid #C6C6C6;
    }

    .form-wrap {
        padding: 1rem 6rem;
        text-align: left;
    }

    .input-wrap {
        margin-bottom: 2rem;
    }

    @media only screen and (max-width: 600px) {
        .outerwrap {
            flex-direction: column;
        }

        .mainImg {
            padding: 0 !important;
        }
    }
</style>