<template>
    <div class="header flex justify-between">
        <div class="flex items-center">
            <a class="logowidth" href="https://www.absltd.co.uk"><img class="logo" src="../assets/img/logo-white.png"></a>
            <div class="buildquote">Build a Quote</div>
        </div>
        <div class="flex items-center">
            <a href="https://www.absltd.co.uk/contact-us/" class="nh">Need Help?</a>
            <a href="https://www.absltd.co.uk/contact-us/" class="contact-us item-center flex"><span><img class="envSVG" src="../assets/img/envelope-light.svg"></span>Contact us</a>
        </div>
    </div>

    <div class="flex headerLower items-center justify-between">
        <h1>Welcome to our quote builder</h1>
        <div class="tracker flex">
            <div class="trackerBox trackerBox1" :class="{active : progress >= 1}">1</div>
            <div class="trackerBox" :class="{active : progress >= 2}">2</div>
            <div class="trackerBox" :class="{active : progress >= 3}">3</div>
            
        </div>
    </div>

    

</template>

<script>
export default {
    props: ['progress'],
    
}
</script>

<style scoped>
    .envSVG {
        width: 20px;
        height: 20px;
        margin-bottom: 0;
        padding-right: 10px;
    }

    .header {
        background-color: #173383;
        color: white;
        padding: 2rem;
    }

    .headerLower {
        padding: 2rem;
        background-color: #EDEDED;
    }

    .buildquote, .contact-us {
        border-left: 2px solid white;
        padding-left: 20px;
        color: white;
        text-decoration: none;
    }

    .logo {
        padding-right: 20px;
        height: 40px;
        width: auto;
    }

    .logowidth {
        width: 142px;
    }

    .nh {
        color: white;
        text-decoration: none;
        width: 128px;
    }

    h1 {
        margin-top: 0;
        color: #173383;
        font-size: 1.6rem;
        margin-bottom: 0;
    }

    .trackerBox { 
        padding: 0.75rem;
        background-color: white;
        color: #909090;
        width: 75px;
        font-weight: bold;
    }

    .trackerBox1 {
        color: black;
    }

    .active {
        background-color: #39A864;
        color: white;
        font-weight: bold;
    }

    @media only screen and (max-width: 600px) {
    .header, .headerLower {
        flex-direction: column;
        align-items: center;
    }

    .header {
        padding: 1.25rem;
    }

    .contact-us {
        width: 120px;
    }

    .buildquote {
        display: none;
    }

    .logowidth {
        margin-bottom: 1rem;
    }
}
</style>