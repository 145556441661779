<template>
     <div class="flex outerwrap items-center">
        
        <div class="w-50 body-wrap">
            <h1>Thank you! One of our team will be in touch shortly to discuss your requirements in detail, so that we can provide an accurate quote.</h1>
            <h2>We look forward to hearing more about your project!</h2>
            
            <p>Want to contact us instead?</p>
            <p><span><i class="fal fa-envelope envSVG"></i></span>sales@absltd.co.uk</p>
            <p><span><i class="fal fa-phone envSVG"></i></span>01623 259 231</p>
        </div>
        <img class="w-50 mainImg" src="../assets/img/mainImg.jpeg">

    </div>
</template>

<script>
//
</script>

<style scoped>

    .envSVG {
        width: 20px;
        height: 20px;
        margin-bottom: 0;
        padding-right: 10px;
    }

    h1 {
        font-size: 1.4rem;
        margin-bottom: 2rem;
    }

    h2 {
        color: #525252;
        font-size: 1.4rem;
        margin-bottom: 2rem;
    }
    
    p {
        font-weight: bold;
        font-size: 1.2rem;
    }

    @media only screen and (max-width: 600px) {
        h1, h2 {
            font-size: 1rem;
        }
    }

</style>