<template>
  <Header :progress="progress"  />
  <Body v-if="progress == 0" :formData="formData" @progressclicked="addProgress" @formChanged="changeFormData"/>
  <BodyPageChoice v-if="progress == 1" @progressBack="progressBack" @progressAndChoice="progressAndChoice" />
  <BodyPage2 v-if="progress == 2 && choice =='Windows'" @progressBack="progressBack" @progressclicked="addProgress" @emitData="windowData" @calcSize="calculateSize" />
  <BodyPage2Doors v-if="progress == 2 && choice =='Door'" @progressBack="progressBack" @progressAndDoor="progressAndDoorData" />
  <BodyPage3 v-if="progress == 3" 
      @progressBack="progressBack"  @progressclicked="addProgressAndEmail($event)" @changeHang="changeHang"
      :windowSize="windowSize" :priceLower="priceLower" :priceHigher="priceHigher" 
      :choice="this.choice" :openingType="this.openingType" :openingRequired="this.opening.openingRequired"
  />
  <BodyPage4 v-if="progress == 4" />
</template>

<script>

import Header from './components/Header.vue';
import Body from './components/BodyPage1.vue';
import BodyPage2 from './components/BodyPage2.vue';
import BodyPage2Doors from './components/BodyPage2Doors.vue';
import BodyPage3 from './components/BodyPage3.vue';
import BodyPage4 from './components/BodyPage4.vue';
import BodyPageChoice from './components/BodyPageChoice.vue';



import emailjs from 'emailjs-com';

export default {
  name: 'App',
  components: { Header, Body, BodyPage2, BodyPage2Doors, BodyPage3, BodyPage4, BodyPageChoice },
  data() {
    return {
      progress: 0,
      formData: {
        name: '',
        postcode: '',
        email: '',
        telephone: ''
      },
      choice: 'Windows',
      type: {
          arched: false,
          circular: false,
          double: false,
          single: false
          },
      opening: {
          noOpening: false,
          openingRequired: false
      },
      openingType: '',
      dimensions: {
          width: '',
          height: '',
          diameter: ''
      },
      windowSize: '',
      priceLower: '£0000',
      priceHigher: '£10000'
    }
  },
  methods: {
    addProgress() {
          this.progress++
    },
    progressBack() {
      this.progress--
    },
    progressAndChoice(choice) {
      
      this.choice = choice
      this.progress++
     
    },
    addProgressAndEmail($event) {
      // Add Progress First
      this.progress++

      var windowType;
      var Opening;
      var Size;
      var openingType;

      if ( this.type.arched == true ) {
        windowType = 'Arched Window';
        Size = 'Width: ' + this.dimensions.width + ' - Height: ' + this.dimensions.height
      } else if( this.type.circular == true ) {
        windowType = 'Circular Window';
        Size = this.dimensions.diameter
      } else if ( this.type.single == true ) {
        windowType = 'Single Door'
        Size = 'Width: ' + this.dimensions.width + ' - Height: ' + this.dimensions.height
      } else if ( this.type.double == true ) {
        windowType = 'Double Door'
        Size = 'Width: ' + this.dimensions.width + ' - Height: ' + this.dimensions.height
      }

      if ( this.opening.noOpening == true ) {
        Opening = 'No Opening Required';
      } else if ( this.opening.openingRequired == true ) {
        Opening = 'Opening Required';
      } else if ( this.choice == 'Door' ) {
        Opening = 'N/A'
      }

      if ( this.openingType == '' ) {
        openingType = 'N/A';
      } else {
        openingType = this.openingType
      }

      var templateParams = {
          name: this.formData.name,
          email: this.formData.email,
          postcode: this.formData.postcode,
          telephone: this.formData.telephone,
          windowType: windowType,
          opening: Opening,
          size: Size,
          openingType: openingType
      };
      
      emailjs.send('service_qt8dpsn', 'template_uht4wli', templateParams, 'user_Zc4YrCUopzcDeoUicTSvd')
      .then(function(response) {
       
      }, function(error) {
       
      });
    },
    
    changeFormData(input) {
      this.formData[input.name] = input.value
    },
    windowData(data) {
      this.type = data.type    
      this.opening = data.opening
      this.dimensions = data.dimensions
      if (data.openingType.topHung == true) {
        this.openingType = 'Top Hung'
      } else if ( data.openingType.sideHung == true ) {
        this.openingType = 'Side Hung'
      }
    },
    progressAndDoorData(doorStyle, dimensions) {
      this.type[doorStyle] = true
      this.dimensions.width = dimensions.width
      this.dimensions.height = dimensions.height
      
      this.progress++

      this.windowSize = doorStyle.charAt(0).toUpperCase() + doorStyle.substr(1)
      
      if(doorStyle == 'single') {
        this.priceLower = '£3000'
        this.priceHigher = '£3500'
      }

      if(doorStyle == 'double') {
        this.priceLower = '£5000'
        this.priceHigher = '£5500'
      }
    },
    calculateSize(data) {

      if (data.type.circular) {
        let diameter = data.dimensions.diameter

        if(data.opening.noOpening == true) {
          if ( diameter < 800) {
            this.windowSize = 'Small'
            this.priceLower = '£375'
            this.priceHigher = '£425'
          }

          if ( diameter >= 800 && diameter < 1100) {
            this.windowSize = 'Medium'
            this.priceLower = '£400'
            this.priceHigher = '£450'
          }

          if ( diameter > 1100) {
            this.windowSize = 'Large'
            this.priceLower = '£450'
            this.priceHigher = '£500'
          }
        }

        if(data.opening.openingRequired == true) {
          if ( diameter < 700) {
            this.windowSize = 'Small'
            this.priceLower = '£740'
            this.priceHigher = '£800'
          }

          if ( diameter >= 700 && diameter < 900 ) {
            this.windowSize = 'Small'
            this.priceLower = '£775'
            this.priceHigher = '£825'
          }

          if ( diameter >= 900 && diameter < 1000) {
            this.windowSize = 'Medium'
            this.priceLower = '£800'
            this.priceHigher = '£850'
          }

          

          if ( diameter >= 1000 && diameter < 1100) {
            this.windowSize = 'Medium'
            this.priceLower = '£840'
            this.priceHigher = '£900'
          }

          if ( diameter >= 1100 && diameter < 1300) {
            this.windowSize = 'Large'
            this.priceLower = '£900'
            this.priceHigher = '£1000'
          }

          if ( diameter >= 1300 && diameter < 1500) {
            this.windowSize = 'Large'
            this.priceLower = '£1000'
            this.priceHigher = '£1100'
          }
          
        }
      }

      if ( data.type.arched == true ) {
        let width = data.dimensions.width
        let height = data.dimensions.height
        let c = width / 2
        let c2 = c * c
        let h2 = height * height
        let diameter = (h2 + c2) / height
        let radius = diameter / 2
    

        let x = Math.asin(width / diameter)
        let rads = x*2
        let arcLength = rads * radius
        let length = arcLength + Number(width) + 900

        let fabprice = 580
        let curveprice = arcLength * 0.08
        let materialCost = (length * 0.025)

        let cost = fabprice + curveprice + materialCost


        if(data.opening.noOpening == true) {

          this.priceLower = '£' + Math.floor(cost)
          this.priceHigher = '£' + Math.floor(cost + 75)

          if ( cost < 500) {
            this.windowSize = 'Small'
            
          }

          if ( cost >= 500 && cost < 800) {
            this.windowSize = 'Medium'          
          }

          if ( cost > 800) {
            this.windowSize = 'Large'
            
          }

        }

        if(data.opening.openingRequired == true) {

          this.priceLower = '£' + Math.floor(cost+580)
          this.priceHigher = '£' + Math.floor(cost + 75 + 580)

          if ( cost < 500) {
            this.windowSize = 'Small'
            
          }

          if ( cost >= 500 && cost < 800) {
            this.windowSize = 'Medium'          
          }

          if ( cost > 800) {
            this.windowSize = 'Large'
            
          }
          
        }
      }
      
    }
  }
}
</script>

